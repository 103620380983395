import React, {useCallback} from 'react';
import './App.scss';
import Particles from "react-particles";
import {loadFull} from "tsparticles";
import type {Engine} from "tsparticles-engine";
import bgOptions from "./particles.config";
import {
    Alert,
    AlertTitle,
    Box, Card,
    CardActionArea, CardActions,
    CardContent,
    CardMedia,
    Container,
    Grid, IconButton,
    Typography
} from "@mui/material";
import ShareIcon from '@mui/icons-material/Reply';
//import LikeIcon from '@mui/icons-material/ThumbUp';
import {Project, data} from "./_config";


function App() {

    const particlesInit = useCallback(async (engine: Engine) => {
        await loadFull(engine);
    }, []);

    const share = (data:Project) => {
        if (navigator.share) {
            navigator
                .share({
                    title: data.title,
                    text: data.description,
                    url: (data.url.includes('//')) ? data.url : 'https://lab.yukari.top/'+data.url,
                })
                .then(() => {
                    console.log('分享成功');
                })
                .catch(error => {
                    console.error('无法分享', error);
                });
        }
    }

    const like = (data:Project) => {

    }

    return (
        <Container maxWidth={'xl'}>
            <Box sx={{my: 3}}>
                <Alert severity="warning">
                    <AlertTitle>欢迎来到 XIAO YUAN FAN LAB</AlertTitle>
                    这里是小缘粉丝俱乐部研发和测试中的项目，全都属于实验性质，部分功能可能会不完善或失效，内容仅供参考。
                </Alert>
            </Box>
            <Grid container spacing={3}>
                { data && data.map((project: Project, idx) => (
                    <Grid item xs={12} md={6} lg={4} key={'project-'+idx}>
                        <Card sx={{height:'100%',display:"flex",flexDirection:"column"}}>
                            <CardActionArea sx={{height:'100%'}} href={project.url}>
                                <CardMedia
                                    component="img"
                                    image={"images/" + (project.image || "default.png")}
                                    alt={project.title}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {project.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {project.description}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions sx={{mt:"auto",py:0}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>
                                        {/*<IconButton aria-label="点赞" size="large" onClick={() => like(project)} title={"点赞"}>
                                            <LikeIcon />
                                        </IconButton>*/}
                                    </Grid>
                                    <Grid item xs={8}>

                                    </Grid>
                                    <Grid item xs={2} sx={{textAlign:'right'}}>
                                        <IconButton aria-label="分享" size="large" onClick={() => share(project)} sx={{transform: "scaleX(-1)"}} title={"分享"}>
                                            <ShareIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>

                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Particles
                id="background"
                init={particlesInit}
                params={bgOptions}
            />
            <Box sx={{textAlign: 'center',mt:5}}>
                &copy; XIAO YUAN FAN LAB<br />
                <a href={'https://xiaoyuan.club/'} target={'_blank'} rel="noreferrer" ><img src={'https://xiaoyuan.club/badge.png'} alt={'小缘粉丝俱乐部'} /></a><br />
                由 YUKARI FAN CLUB 提供</Box>
        </Container>
    );
}

export default App;
