const data: Project[] = [
  {
    "title": "直播预告＆概率",
    "url": "live-probability",
    "description": "今天直播吗？直播预告每天更新。直播概率由2016年12月的数据开始统计，计算仅供参考。事实上每天都可能随缘直播。",
    "image": "live-probability.png"
  },
  {
    "title": "粉丝牌查询",
    "url": "fan-medals",
    "description": "查看在196直播间里曾经佩戴过的粉丝勋章",
    "image": "fan-medals.png"
  },
  {
    "title": "小缘动态",
    "url": "social-feed",
    "description": "整合小缘在B站、微博等社交媒体上的动态",
    "image": ""
  }
]

interface Project {
  "title": string,
  "url": string,
  "description": string,
  "image": string|null
}


export {data};
export type { Project };
